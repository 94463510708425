import { HtmlProcessorExtensionProvider } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import { Button } from "@wsui/base";
import React from "react";

export const wrapRootElement = ({ element }) => {
  return (
    <HtmlProcessorExtensionProvider
      treeTransforms={[
        (tree, {visit}) => {
          visit(tree, {tagName: "a"}, (node) => {
            if (node.properties && node.properties.className) {
              if (node.properties.className.some((className) => className === "btn")) {
                node.tagName = "wp-button"
              }
            }
          })

        }
      ]}
      stringifierComponents={{
        "wp-button": ({ href, children }) => {
          return (<Button href={href} variant="primary">{children}</Button>)
        },
      }}>
      {element}
    </HtmlProcessorExtensionProvider>
  );
};
